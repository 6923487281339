import { ConfigProvider } from "antd";
import "./App.css";
import AppRoute from "./Router/AppRoute";
import { theme } from "./Config/theme";

function App() {
  return (
    <div className="App">
      <ConfigProvider theme={theme}>
        <AppRoute />
      </ConfigProvider>
    </div>
  );
}

export default App;
