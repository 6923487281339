export const colorPalette = ["#c92b2e", "#3f5ac5", "#f4bc4c"];

export const theme = {
  token: {
    colorPrimary: colorPalette[0],
    colorTextHeading: colorPalette[0],
    fontSizeHeading1: 55,
    fontSizeHeading2: 40,
    fontSizeHeading3: 25,
    colorLink: "white",
    fontFamily: `"Public Sans", -apple-system, BlinkMacSystemFont,
               "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
             "Helvetica Neue", sans-serif`,
    borderRadius: 8,
    padding: 16,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
  components: {
    Typography: {
      // Increase font size for Paragraph
      fontSize: 16,
    },
    Input: {
      borderRadius: 0,
      controlHeight: 40,
    },
    Select: {
      borderRadius: 0,
      controlHeight: 40,
      colorText: "black",
    },
  },

  cssVar: true,
  hashed: false,
};
