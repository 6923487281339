import { Layout, Typography, Space } from "antd";
import { contact_path } from "../Config/Config";

const { Footer } = Layout;
const { Text } = Typography;

const FooterComponent = () => {
  return (
    <Footer className="footer">
      <Space direction="vertical" className="footer-content ">
        <Text strong>
          <a href="/" target="_blank" rel="noopener noreferrer">
            Azkroflyz © 2024
          </a>{" "}
        </Text>
        <Space size="large">
          <a href={contact_path}>
            <Text className="footer-link">Contact</Text>
          </a>
        </Space>
      </Space>
    </Footer>
  );
};

export default FooterComponent;
