// MainLayout.tsx
import React from "react";
import { Layout } from "antd";
import NavBar from "../Layout/Header";
import FooterComponent from "../Layout/Footer";

const { Content } = Layout;

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <>
      <NavBar background="white" />
      <Content style={{ padding: "0", minHeight: "82vh" }}>{children}</Content>
      <FooterComponent />
    </>
  );
};

export default MainLayout;
